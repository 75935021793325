import React from "react";

const Services = () => {
  return (
    <div className="services-section aboutUs-item">
      <h1 className="heading-primary">Services</h1>
      <div className="description">
        <div className="services-summary">
          <span>
            Acropolis Tech is a digital solutions provider offering a wide range
            of services to help businesses grow and thrive. We partner with our
            clients to design, develop, and implement custom solutions while
            enhancing their customers' experiences. Our team specializes in
            custom web design and development, complex A/B test development, and
            quality assurance services to ensure websites are bug-free and
            functioning as intended.
          </span>
          <span>
            In addition to our core services, we also offer digital marketing,
            e-commerce solutions, and mobile app development. With our expertise
            and dedication, we provide highly experienced resources at a great
            cost, making our clients and us happy. Partner with us and
            experience the Acropolis Tech difference.
          </span>
        </div>
      </div>
      <div className="services-button">
        <button>Explore Services</button>
      </div>
    </div>
  );
};

export default Services;
