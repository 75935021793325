import React from "react";
import HeroSection from "../HeroSection/HeroSection";
import ServiceSection from "../ServiceSection/ServiceSection";
import ToolSection from "../ToolSection/ToolSection";
import ClientSection from "../ClientSection/ClientSection";
import AboutUsSection from "../AboutUsSection/AboutUsSection";

const BodySection = () => {
  return (
    <div className="body-section-main">
      <HeroSection />
      <ServiceSection />
      <ToolSection />
      <ClientSection />
      <AboutUsSection />
    </div>
  );
};

export default BodySection;
