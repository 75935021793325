import ABTestLogo from "../../images/services/logo/ABTest-lg.png";
import SplitTestLogo from "../../images/services/logo/SplitTest-lg.png";
import BugFixingLogo from "../../images/services/logo/BugFix-lg.png";
import SoftwareDevelopmentLogo from "../../images/services/logo/SotwareDevelopment-lg.png";
import MobileAppDevelopmentLogo from "../../images/services/logo/MobileAppDevelopment-lg.png";

import abTasty from "../../images/tools/abtasty.png";
import exponeaBloomreach from "../../images/tools/exponea-bloomreach.svg";
import kameleoon from "../../images/tools/kameleoon.svg";
import adobeTarget from "../../images/tools/adobetarget.png";
import dynamicYield from "../../images/tools/dynamic-yield.png";
import googleOptimize from "../../images/tools/google-optimize.png";
import optimizely from "../../images/tools/optimizely.png";
import vwo from "../../images/tools/vwo.png";

import jawliner from "../../images/clients/jawliner.webp";
import beadPatternMaker from "../../images/clients/beadPatternMaker.svg";
import athleticsMotion from "../../images/clients/athleticsmotionusa.avif";

const data = {
  services: [
    {
      name: "A/B Testing",
      shortName: "A/B Test",
      desc: "<span>We have extensive experience and expertise in conducting A/B tests for websites and applications. Our team of experts can help you design and implement A/B tests that range from simple to complex, so you can optimize your website or application for maximum impact.</span><br/><br/><span>We understand that every business is unique, and that's why we offer customized A/B testing services that are tailored to your specific needs. Whether you're looking to test a single element or conduct a comprehensive optimization strategy, we can help.</span>",
      logo: ABTestLogo,
    },
    {
      name: "Split Test",
      shortName: "Split Test",
      desc: "<span>Our team of experts can help you design and implement split tests that are tailored to your specific needs. We work with you to identify the elements you want to test and develop multiple variations of those elements, so you can collect data on user behaviour and make informed decisions about how to optimize your website or application.</span><br/><br/><span>We use advanced statistical techniques to ensure that our results are accurate and reliable, and we provide detailed reports that explain our findings and recommendations. Whether you're looking to increase click-through rates, improve conversion rates, or reduce bounce rates, we can help you achieve your goals with split testing.</span>",
      logo: SplitTestLogo,
    },
    {
      name: "Bug Fixing",
      shortName: "Bug Fix",
      desc: "<span>Our team of experts has extensive experience in identifying and resolving bugs and other issues related to A/B testing and web development. We work quickly and efficiently to develop a solution that resolves the issue and prevents it from recurring in the future.</span><br/><br/><span>We offer customized bug-fixing services that are tailored to your specific needs. We work with you to understand your goals and objectives, and we develop a comprehensive strategy that addresses your specific issues related to A/B testing and web development.</span>",
      logo: BugFixingLogo,
    },
    {
      name: "Software Development",
      shortName: "Software",
      desc: "<span>We use cutting-edge technologies and techniques to develop high-quality software solutions that are customized to your specific needs, ensuring that your A/B testing and Web App Development efforts are integrated seamlessly into your digital strategy.</span><br/><br/><span>We offer customized software development services that are tailored to your specific needs. We work with you to understand your goals and objectives, and we develop a comprehensive strategy that addresses your specific issues.</span>",
      logo: SoftwareDevelopmentLogo,
    },
    {
      name: "Mobile App Development",
      shortName: "Mobile App",
      desc: "<span>Mobile app development is a complex process that requires a range of technical skills and design expertise. Our team has extensive experience in this field and can provide a range of services to help you bring your mobile app idea to life.</span><br/><br/><span>Our team has expertise in various programming languages such as Java, Swift, and Kotlin, and can develop mobile apps using popular frameworks and tools like React Native, Flutter, and Xcode. We have experience in creating both iOS and Android apps, ensuring that your app is accessible to the widest possible audience.",
      logo: MobileAppDevelopmentLogo,
    },
  ],
  tools: [
    {
      name: "Optimizely",
      desc: "Optimizely is a widely-used experimentation and optimization platform that helps businesses test and improve their digital experiences. The platform provides a range of tools for A/B testing, multivariate testing, and personalization, which allow businesses to test different variations of their website or mobile app and identify the best-performing options. Optimizely's user-friendly interface makes it easy for users to create and launch experiments without the need for coding or technical skills. Additionally, the platform offers powerful analytics and reporting capabilities, enabling businesses to track their experiments and measure their impact on key metrics such as engagement, conversion, and revenue. With its focus on data-driven optimization, Optimizely is a valuable tool for businesses looking to improve their ROI and deliver exceptional digital experiences to their customers.",
      img: optimizely,
    },
    {
      name: "Dynamic Yield",
      desc: "Dynamic Yield is a personalization platform that helps businesses deliver customized digital experiences to their customers. The platform provides a range of tools for behavioral targeting, segmentation, and A/B testing, which allow businesses to deliver tailored content and offers to their audiences. Dynamic Yield's advanced algorithms use real-time data and machine learning to identify patterns and make predictions about customer behavior, enabling businesses to optimize their content and offers in real-time. The platform's easy-to-use interface and robust integrations with third-party systems make it a popular choice for businesses of all sizes. Additionally, Dynamic Yield offers extensive reporting and analytics capabilities, providing businesses with valuable insights into their customer behavior and the effectiveness of their personalization strategies. With its focus on delivering personalized experiences at scale, Dynamic Yield is a powerful tool for businesses looking to improve customer engagement, conversion, and loyalty.",
      img: dynamicYield,
    },
    {
      name: "ABTasty",
      desc: "AB Tasty is an all-in-one optimization platform that allows businesses to improve their digital experiences through A/B testing, personalization, and user engagement tools. The platform provides a range of features such as split testing, multivariate testing, and funnel testing, allowing businesses to experiment with different variations of their website or mobile app and identify the most effective options. AB Tasty's personalization capabilities enable businesses to deliver tailored experiences to their audiences based on their behavior, preferences, and demographics. The platform's user-friendly interface and drag-and-drop editor make it easy for users to create and launch experiments without the need for coding or technical skills. Additionally, AB Tasty offers a variety of engagement tools, such as surveys, on-site messages, and pop-ups, that enable businesses to interact with their customers and gather feedback. The platform also provides robust analytics and reporting capabilities, allowing businesses to measure the impact of their experiments and optimize their digital experiences based on data-driven insights. With its focus on user-centric optimization, AB Tasty is a valuable tool for businesses looking to improve customer satisfaction, retention, and conversion.",
      img: abTasty,
    },
    {
      name: "Adobe Target",
      desc: "Adobe Target is a personalization and testing platform that enables businesses to deliver relevant and personalized digital experiences to their customers. The platform provides a range of tools for A/B testing, multivariate testing, and personalization, allowing businesses to experiment with different variations of their website or mobile app and deliver targeted content and offers to their audiences. Adobe Target's advanced algorithms use real-time data and machine learning to identify customer behavior patterns and make predictions about their preferences and actions, enabling businesses to optimize their experiences in real-time. The platform's user-friendly interface and intuitive visual editor make it easy for users to create and launch experiments without the need for coding or technical skills. Additionally, Adobe Target offers robust audience segmentation and targeting capabilities, allowing businesses to deliver personalized experiences based on factors such as behavior, location, and device. The platform also provides extensive reporting and analytics capabilities, enabling businesses to measure the impact of their experiments and optimize their digital experiences based on data-driven insights. With its focus on delivering personalized experiences at scale, Adobe Target is a powerful tool for businesses looking to improve customer engagement, loyalty, and conversion.",
      img: adobeTarget,
    },
    {
      name: "VWO",
      desc: "VWO is an all-in-one optimization platform that helps businesses improve their digital experiences through A/B testing, multivariate testing, and personalization. The platform provides a range of features such as heatmaps, click maps, and session recordings, allowing businesses to gather insights about user behavior and optimize their website or mobile app accordingly. VWO's personalization capabilities enable businesses to deliver customized experiences to their audiences based on their behavior, location, and device. The platform's easy-to-use interface and visual editor make it simple for users to create and launch experiments without the need for coding or technical skills. Additionally, VWO offers a variety of engagement tools, such as surveys, on-site messages, and pop-ups, that enable businesses to interact with their customers and gather feedback. The platform also provides robust reporting and analytics capabilities, allowing businesses to measure the impact of their experiments and optimize their digital experiences based on data-driven insights. With its focus on user-centric optimization, VWO is a valuable tool for businesses looking to improve customer satisfaction, retention, and conversion.",
      img: vwo,
    },
    {
      name: "Kameleoon",
      desc: "Kameleoon is a personalization and experimentation platform that helps businesses deliver optimized digital experiences to their customers. The platform provides a range of tools for A/B testing, multivariate testing, and personalization, enabling businesses to experiment with different variations of their website or mobile app and deliver tailored content and offers to their audiences. Kameleoon's advanced algorithms use real-time data and machine learning to identify patterns in customer behavior and make predictions about their preferences and actions, enabling businesses to optimize their experiences in real-time. The platform's user-friendly interface and drag-and-drop editor make it easy for users to create and launch experiments without the need for coding or technical skills. Additionally, Kameleoon offers a variety of engagement tools, such as surveys, on-site messages, and pop-ups, that enable businesses to interact with their customers and gather feedback. The platform also provides extensive reporting and analytics capabilities, allowing businesses to measure the impact of their experiments and optimize their digital experiences based on data-driven insights. With its focus on delivering personalized experiences at scale, Kameleoon is a valuable tool for businesses looking to improve customer engagement, loyalty, and conversion.",
      img: kameleoon,
    },
    {
      name: "Exponea Bloomreach",
      desc: "Exponea Bloomreach is a customer experience platform that combines the power of personalization and content management to help businesses create seamless and personalized digital experiences for their customers. The platform provides a range of tools for A/B testing, multivariate testing, and personalization, allowing businesses to experiment with different variations of their website or mobile app and deliver targeted content and offers to their audiences. Exponea Bloomreach's content management capabilities enable businesses to manage their website content and product catalog in a unified, user-friendly interface, enabling faster and more effective content updates. The platform's advanced algorithms use real-time data and machine learning to identify customer behavior patterns and make predictions about their preferences and actions, enabling businesses to optimize their experiences in real-time. The platform also provides robust reporting and analytics capabilities, allowing businesses to measure the impact of their experiments and optimize their digital experiences based on data-driven insights. With its focus on delivering personalized experiences at scale, Exponea Bloomreach is a valuable tool for businesses looking to improve customer engagement, loyalty, and conversion.",
      img: exponeaBloomreach,
    },
    {
      name: "Google Optimize",
      desc: "Google Optimize is a free optimization platform that enables businesses to improve their digital experiences through A/B testing, multivariate testing, and personalization. The platform provides a range of features such as heatmaps, click maps, and session recordings, allowing businesses to gather insights about user behavior and optimize their website or mobile app accordingly. Google Optimize's easy-to-use interface and visual editor make it simple for users to create and launch experiments without the need for coding or technical skills. Additionally, the platform integrates seamlessly with other Google tools such as Google Analytics, enabling businesses to measure the impact of their experiments and optimize their digital experiences based on data-driven insights. With its focus on delivering personalized experiences at scale, Google Optimize is a valuable tool for businesses of all sizes looking to improve customer engagement, loyalty, and conversion without investing in expensive optimization platforms.",
      img: googleOptimize,
    },
  ],
  clients: [
    {
      name: "Jawliner",
      desc: "<span>Jawliner is a brand that offers chewing gums and jaw training devices to train the jaw muscles and achieve a defined jawline. They also offer a variety of flavors such as ginger/lime + mint + cinnamon/honey. You can buy their products on their website.</span><br/><br/><span>Jawliner’s chewing gum is designed to help you achieve a defined jawline by training your jaw muscles. The gum has a nice minty taste to start and the consistency is perfect. It can help tire your jaw muscles and you may wake up with less tension.</span><br/><br/><span>The Jawliner device is another product that Jawliner offers. It is designed to train your jaw muscles to become a strong, sexy and masculine jawline. It is like a really powerful chewing gum.</span><br/><br/><span>Jawliner has partners in Vietnam, USA, Belgium/Netherlands and France1.</span><br/><br/>",
      img: jawliner,
    },
    {
      name: "Bead Pattern Maker",
      desc: "<span>Bead Pattern Maker is an online tool that lets you create pattern designs in just a few clicks. It covers various free online editing tools, so you can create pattern designs easily. You can try it for free on their website.</span><br/><br/><span>Bead Pattern Maker is a great tool for creating bead patterns. It is easy to use and has a lot of features that make it a great choice for anyone who wants to create bead patterns.</span><br/><br/><span>Bead Pattern Maker lets you create patterns using different types of beads such as seed beads, cylinder beads or plastic fuse beads.</span><br/><br/><span>Bead Pattern Maker also has a YouTube channel where you can learn how to get started with Bead Pattern Maker.</span><br/><br/>",
      img: beadPatternMaker,
    },
    {
      name: "Athletics Motion",
      desc: "<span>Athletics Motion is a company that offers premium athletic apparel that combines strength and beauty of women in the fitness world. They offer a variety of products such as leggings, sports bras, shorts, and more.</span><br/><br/><span>Athletics Motion offers 100% squat proof (not transparent) leggings that are super comfortable for working out and provide back support. They also offer a 60-day money-back guarantee, free shipping (1-3 days), free returns & exchanges, and 24/7 customer support.</span><br/><br/><span>You can visit their website to learn more about their products.</span><br/><br/>",
      img: athleticsMotion,
    },
  ],
};

export default data;

// </span><br/><br/><span>We understand the importance of a well-designed user interface and have a team of UX/UI designers who can help create visually appealing and easy-to-use interfaces. We use graphic design software like Sketch and Adobe XD to create high-quality designs that meet your needs and exceed your expectations.</span>
