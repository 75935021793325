import React from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

import data from "../../helper/data";

const ClientSection = () => {
  return (
    <div className="clients-main">
      <div className="clients-main-image">
        <h3>
          "Our Success is Measured by the Growth and Results We Help Our Clients
          Achieve through Quality Development, Efficient Testing, and Precise QA
          Processes."
        </h3>
      </div>
      <div className="clients-main-box">
        <h1 className="heading-primary">Our Clients</h1>
        <p className="description-primary">
          We believe in creating value for our clients through a comprehensive
          approach that includes quality development, efficient testing, and
          precise QA processes. Our clients trust us to help them improve their
          pipeline and revenue, and we take that responsibility seriously.We
          work closely with our clients to understand their unique needs and
          tailor our solutions to meet those needs. We measure our success by
          the growth and results we help our clients achieve, and we're proud to
          have helped numerous organizations achieve significant improvements in
          their revenue and bottom line. Our team of experts is committed to
          staying on the cutting edge of technology and development best
          practices, so that we can continue to provide the highest quality
          services to our clients. When you partner with us, you can trust that
          you're getting a partner who is dedicated to your success.
        </p>
        <div className="clients-name-box">
          {data.clients.map((client, idx) => {
            const { name, desc, img } = client;
            return (
              <div className="clients-opt" key={name}>
                <img src={img} alt={name} id={`elem-${idx}`} />
                <ReactTooltip
                  className="tooltip description-primary"
                  anchorId={`elem-${idx}`}
                  // dangerouslySetInnerHTML={{ __html: desc }}
                  // place="bottom"
                  html={desc}
                />
              </div>
            );
          })}
          <div className="clents-opt-extra" id="elem-extra">
            + And More
          </div>
          <ReactTooltip
            className="tooltip description-primary"
            anchorId="elem-extra"
            // dangerouslySetInnerHTML={{ __html: desc }}
            // place="bottom"
            content={"We are happy to work for you!"}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientSection;
