import React from "react";

const Card = ({ name, desc, logo }) => {
  return (
    <div className="card-primary">
      <div className="card-box-main">
        <div className="card-box-holder">
          <div className="card-header">
            <img src={logo} alt={name} />
          </div>
          <div className="card-body">
            <h2 className="heading-primary">{name}</h2>
            <p
              className="description-primary"
              dangerouslySetInnerHTML={{
                __html: desc,
              }}
            ></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
