import React from "react";

const NavigationArrow = () => {
  return (
    <div className="swiper-navigation">
      <div className="swiper-navigation-next">
        <img
          width="32"
          height="32"
          className="next-white"
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNMjIgMTUuOTk3di0uMDAzYzAtLjQwNC0uMTc2LS43NjgtLjQ2LTEuMDI4aC4wMDJsLTktOC41NzItLjAwNC4wMDRDMTIuMjY4IDYuMTUgMTEuOTAyIDYgMTEuNSA2Yy0uODMgMC0xLjUuNjQtMS41IDEuNDMgMCAuNDMyLjIwMy44Mi41MjMgMS4wODJsNy44NiA3LjQ4NS03Ljk2MyA3LjU4M2MtLjI2LjI1Ny0uNDIuNjA2LS40Mi45OSAwIC43OS42NyAxLjQzIDEuNSAxLjQzLjQwNCAwIC43Ny0uMTUzIDEuMDQtLjRsOS4wMDItOC41N3YtLjAwMmMuMjgyLS4yNi40NTgtLjYyNC40NTgtMS4wMjh2LS4wMDN6Ig0KICAgICAgICAgIGZpbGw9IiMwMDQwNTIiIG9wYWNpdHk9IjEiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+"
          alt="Next"
        />
        <img
          width="32"
          height="32"
          className="next-black"
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNMjIgMTUuOTk3di0uMDAzYzAtLjQwNC0uMTc2LS43NjgtLjQ2LTEuMDI4aC4wMDJsLTktOC41NzItLjAwNC4wMDRDMTIuMjY4IDYuMTUgMTEuOTAyIDYgMTEuNSA2Yy0uODMgMC0xLjUuNjQtMS41IDEuNDMgMCAuNDMyLjIwMy44Mi41MjMgMS4wODJsNy44NiA3LjQ4NS03Ljk2MyA3LjU4M2MtLjI2LjI1Ny0uNDIuNjA2LS40Mi45OSAwIC43OS42NyAxLjQzIDEuNSAxLjQzLjQwNCAwIC43Ny0uMTUzIDEuMDQtLjRsOS4wMDItOC41N3YtLjAwMmMuMjgyLS4yNi40NTgtLjYyNC40NTgtMS4wMjh2LS4wMDN6Ig0KICAgICAgICAgIGZpbGw9IiNmZmZmZmYiIG9wYWNpdHk9IjAuOCIgZmlsbC1ydWxlPSJldmVub2RkIi8+DQo8L3N2Zz4="
          alt="Next"
        />
      </div>
      <div className="swiper-navigation-prev">
        <img
          width="32"
          height="32"
          className="prev-white"
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNMjEuNTggMjMuNThsLTcuOTYyLTcuNTgzIDcuODYtNy40ODVjLjMyLS4yNjIuNTIyLS42NS41MjItMS4wODNDMjIgNi42NCAyMS4zMjggNiAyMC41IDZjLS40MDMgMC0uNzY4LjE1LTEuMDM4LjM5OGwtLjAwNC0uMDA0LTkgOC41N3YuMDAyYy0uMjgyLjI2LS40NTguNjI0LS40NTggMS4wMjhWMTZjMCAuNDA0LjE3Ni43Ny40NiAxLjAzaC0uMDAybDkgOC41N2MuMjcuMjQ3LjYzOC40IDEuMDQyLjQuODMgMCAxLjUtLjY0IDEuNS0xLjQzIDAtLjM4NC0uMTYtLjczMy0uNDItLjk5eiINCiAgICAgICAgICBmaWxsPSIjMDA0MDUyIiBvcGFjaXR5PSIxIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4NCjwvc3ZnPg=="
          alt="Previous"
        />
        <img
          width="32"
          height="32"
          className="prev-black"
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNMjEuNTggMjMuNThsLTcuOTYyLTcuNTgzIDcuODYtNy40ODVjLjMyLS4yNjIuNTIyLS42NS41MjItMS4wODNDMjIgNi42NCAyMS4zMjggNiAyMC41IDZjLS40MDMgMC0uNzY4LjE1LTEuMDM4LjM5OGwtLjAwNC0uMDA0LTkgOC41N3YuMDAyYy0uMjgyLjI2LS40NTguNjI0LS40NTggMS4wMjhWMTZjMCAuNDA0LjE3Ni43Ny40NiAxLjAzaC0uMDAybDkgOC41N2MuMjcuMjQ3LjYzOC40IDEuMDQyLjQuODMgMCAxLjUtLjY0IDEuNS0xLjQzIDAtLjM4NC0uMTYtLjczMy0uNDItLjk5eiINCiAgICAgICAgICBmaWxsPSIjZmZmZmZmIiBvcGFjaXR5PSIwLjgiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+"
          alt="Previous"
        />
      </div>
    </div>
  );
};

export default NavigationArrow;
