import React from "react";
import story from "../../../images/aboutUs/story3.png";

const Story = () => {
  return (
    <div className="story-section aboutUs-item">
      <h1 className="heading-primary">Our Story</h1>
      <div className="description">
        <div className="story-summary">
          Welcome to Acropolis Tech, where innovation and technology intersect
          to provide you with cutting-edge solutions for your digital needs. Our
          team of experts is dedicated to solving your problems, whether you
          need help with software development, web design, or digital marketing.
        </div>
        <div className="story-body">
          <div className="story-body-image">
            <img src={story} alt="Our Story" />
          </div>
          <span className="story-body-summary">
            At Acropolis Tech, we believe in challenging the status quo to make
            things better, faster, and more efficient for our clients. We're
            passionate about what we do, and we're committed to delivering
            cost-effective solutions that meet your unique business needs. Our
            approach is client-focused, and we treat each of our clients like
            family.
          </span>
          <span className="story-read-more active"> ...Read More</span>
          <span className="story-body-summary-box">
            <span className="story-body-summary">
              Our journey started with a small team of developers who shared a
              dream of creating innovative solutions for businesses of all
              sizes. With hard work and dedication, we've grown our team and
              expanded our capabilities. Today, we're a team of skilled
              professionals, including QA engineers, designers, developers, and
              digital marketing specialists, working together to deliver the
              best solutions for our clients.
            </span>
            <span className="story-body-summary">
              We work with clients from a variety of industries, including
              healthcare, finance, and e-commerce. Our expertise in software
              development, web design, and digital marketing has helped us build
              strong relationships with our clients, including Fortune 500
              companies.
            </span>
            <span className="story-body-summary">
              At Acropolis Tech, we're committed to responsive design and
              development, keeping your business agile and interesting. Whether
              you need a website redesign, software development, or digital
              marketing services, we have the expertise to help you achieve your
              goals. Join our family of satisfied clients and experience the
              Acropolis Tech difference. Contact us today to learn more about
              our services and how we can help you grow your business.
            </span>
          </span>
          <span className="story-read-less"> ...Read Less</span>
        </div>
      </div>
    </div>
  );
};

export default Story;
