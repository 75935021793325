// import Swiper core and required modules
import React from "react";
import { Navigation, Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";

import data from "../../helper/data";
import NavigationArrow from "../layouts/NavigationArrow";

const ToolSlider = () => {
  let controllerSlider = null;
  return (
    <>
      <div className="swiper-controller">
        <Swiper
          modules={[Navigation, Controller]}
          speed={500}
          centeredSlides={true}
          draggable={true}
          grabCursor={true}
          loop={true}
          slideToClickedSlide={true}
          navigation={{
            nextEl: ".swiper-navigation-next",
            prevEl: ".swiper-navigation-prev",
          }}
          breakpoints={{
            // when window width is >= 768px
            520: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            0: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
          onAfterInit={(swiper) => {
            controllerSlider = swiper;
          }}
        >
          {data.tools.map((tool) => {
            const { name, img } = tool;
            return (
              <SwiperSlide key={name}>
                <div className="tool-image-holder">
                  <img src={img} alt={name} className="tool-image" />
                </div>
                <div className="arrow-down"></div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <NavigationArrow />
      </div>
      <div className="swiper-controlled">
        <Swiper
          modules={[Navigation, Controller]}
          speed={500}
          draggable={false}
          grabCursor={false}
          allowTouchMove={false}
          centeredSlides={true}
          loop={true}
          loopedSlides={3}
          slideToClickedSlide={false}
          onAfterInit={(swiper) => {
            if (!controllerSlider) return;
            controllerSlider.controller.control = swiper;
          }}
          breakpoints={{
            // when window width is >= 768px
            520: {
              loopedSlides: 3,
            },
            0: {
              loopedSlides: 3,
              // spaceBetween: 15,
            },
          }}
        >
          {data.tools.map((tool) => {
            const { name, desc } = tool;
            return (
              <SwiperSlide key={name}>
                <div className="inner-main">
                  <h2 className="heading-primary">{name}</h2>
                  <p
                    className="description-primary"
                    dangerouslySetInnerHTML={{
                      __html: desc,
                    }}
                  ></p>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

export default ToolSlider;
