import React from "react";
import linkedin from "../../../images/linkedin.png";
import twitter from "../../../images/twitter.png";

const SocialLinks = () => {
  return (
    <div className="contactus-social-media">
      <div className="contactus-social-media-twitter">
        <a
          href="https://www.linkedin.com/company/acropolis-tech/"
          target="_blank"
        >
          <img src={linkedin} alt="LinkedIn" />
        </a>
      </div>
      <div className="contactus-social-media-linkedin">
        <a href="https://twitter.com/Acropolis_Tech/" target="_blank">
          <img src={twitter} alt="Twitter" />
        </a>
      </div>
    </div>
  );
};

export default SocialLinks;
