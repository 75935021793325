import React from "react";
import ToolSlider from "./ToolSlider";

const ToolSection = () => {
  return (
    <div className="tools-main">
      <div className="tools-main-box">
        <div className="tools-main-heading">
          <h1 className="heading-primary">Tools We Use</h1>
          <p className="description-primary">
            We have extensive experience working with a variety of Conversion
            Rate Optimization (CRO) tools, including Optimizely, Google
            Optimize, VWO, Dynamic Yield, Adobe Target, Exponea, and others. Our
            team of experts can help you set up and build any A/B tests using
            these tools to optimize your website and improve your conversion
            rates. Whether you're looking to increase sales, improve user
            experience, or achieve other business goals, we can help you get the
            most out of these powerful optimization tools.
          </p>
        </div>
        <div className="tools-slider">
          <div className="tools-slider-main">
            <ToolSlider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolSection;
