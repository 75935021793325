import React from "react";
import { Outlet } from "react-router-dom";

import ContactUs from "./components/ContactUs/ContactUs";
import AboutUsSection from "./components/AboutUsSection/AboutUsSection";
import GoToTopButton from "./components/GoTop/GoToTopButton";

import Footer from "./blogsComponent/Footer/Footer";
import Navbar from "./blogsComponent/Navbar/Navbar";

const Blogs = () => {
  return (
    <div className="blogs-page">
      <ContactUs />
      <GoToTopButton />
      <Navbar />
      <Outlet />
      <AboutUsSection />
      <Footer />
    </div>
  );
};

export default Blogs;
