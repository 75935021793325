import React from "react";
import heroImage from "../../../images/hero-image3.png";

const HeroSection = () => {
  return (
    <div className="hero-main">
      <div className="hero-main-left">
        <h1 className="hero-main-heading heading-primary">
          Test and Optimize every idea and hypothesis.
          <br />
          {/* Test, Learn and Succeed with - Web Experimentation & Feature
          Experimentation. */}
        </h1>
        <h2 className="hero-main-subheading">
          Welcome to Test, Learn and Succeed!
        </h2>
        <p className="hero-main-description description-primary">
          No more guesses with ideas and thinking. It's time for you to test all
          those ideas and thoughts into A/B Tests. We provide a comprehensive
          suite of tools and resources to optimize your websites and
          applications through experimentation. We ensure to test every idea and
          hypothesis through a process of continuous learning and iteration.
        </p>
      </div>
      <div className="hero-main-right">
        <img
          src={heroImage}
          alt="Test and Optimize every idea and hypothesis."
        />
      </div>
    </div>
  );
};

export default HeroSection;
