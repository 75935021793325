import React, { useState } from "react";
import ContactForm from "./ContactForm";
import FormSubmitted from "./FormSubmitted";
import SocialLinks from "../ScocialLinks/SocialLinks";

const ContactUs = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(false);
  return (
    <div className="contactus-section-main">
      <div className="contactus-section-overlay"></div>
      <div className="contactus-section-body-main">
        <div className="contactus-section-box">
          {!isSubmitted ? (
            <>
              <div className="contactus-close-button">
                <div className="line-1"></div>
                <div className="line-3"></div>
              </div>
              <h1 className="heading-primary">Contact US</h1>
              <SocialLinks />
              <ContactForm
                setIsSubmitted={setIsSubmitted}
                setError={setError}
              />
            </>
          ) : (
            <>
              <FormSubmitted error={error} />
              <SocialLinks />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
