import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import data from "../../helper/blogsData";

const BlogDescription = ({
  title,
  img,
  headingSummary,
  bodyContent,
  author,
}) => {
  return (
    <div className="blog-description-main">
      <div className="blog-image">
        <img src={img} alt={title} />
      </div>
      <div className="blog-heading">
        <div className="blog-heading-primary">
          <h1 className="heading-primary">{title}</h1>
          <span className="heading-primary-author">
            <span className="author-img">
              <img src={author.img} alt={author.name} />
            </span>
            <span className="author-info">
              <span className="author-name">{author.name}</span>
              <span className="author-extra">
                <span className="author-position">{author.post}</span>
                <span className="author-linkedin">
                  <a href={author.linkedIn}>
                    <img src={author.linkedinImg} alt="LinkedIn" />
                  </a>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div className="heading-summary">{headingSummary}</div>
      </div>
      <div className="blog-body-main">
        {bodyContent.map((content, idx) => (
          <div className="blog-body" key={idx}>
            {content}
          </div>
        ))}
      </div>
    </div>
  );
};

const BlogsDesc = () => {
  const { state } = useLocation();
  if (!state) return <Navigate to={"/error"} />;
  const { qparam } = state;
  if (qparam >= 0) {
    if (!data[qparam]) return <Navigate to={"/error"} />;
    else {
      const { title, img, headingSummary, bodyContent, author } = data[qparam];
      return (
        <BlogDescription
          title={title}
          img={img}
          headingSummary={headingSummary}
          bodyContent={bodyContent}
          author={author}
        />
      );
    }
  } else {
    return <Navigate to={"/error"} />;
  }
};

export default BlogsDesc;
