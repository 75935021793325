import React from "react";
import BlogsElement from "../BlogsElemet/BlogsElement";

const BlogsHomePage = () => {
  return (
    <div className="blogs-home-main">
      <div className="blogs-home-main-box">
        <h1 className="heading-primary">Our Blogs</h1>
        <BlogsElement />
      </div>
    </div>
  );
};

export default BlogsHomePage;
