import React from "react";
import emailFailed from "../../../images/submitted/emailFailed.png";
import emailSuccess from "../../../images/submitted/emailSuccess.png";

const FormSubmitted = ({ error }) => {
  return (
    <div className="submitted-section-box">
      <div className="submitted-section-image">
        {!error ? (
          <img src={emailSuccess} alt="Email Sent ..." />
        ) : (
          <img src={emailFailed} alt="Email Sent Failed ..." />
        )}
      </div>
      <div className="submitted-section-description">
        {!error ? (
          <p>
            We received your email. We will contact you soon.
            <br />
            <br />
            Have a nice day!
          </p>
        ) : (
          <p>
            Something went wrong.
            {/* <br /> */}
            <br />
            Please try again later.
          </p>
        )}
      </div>
    </div>
  );
};

export default FormSubmitted;
