import React from "react";
import { Link } from "react-router-dom";
import SocialLinks from "../../components/ScocialLinks/SocialLinks";

const Footer = () => {
  return (
    <footer>
      <div className="footer-main">
        <div className="footer-items">
          <Link to={"/"} className="footer-item footer-home">
            Home
          </Link>
          <Link to={"/blogs"} className="footer-item footer-blogs">
            Blogs
          </Link>
          <a className="footer-item btn-secondary">Contact Us</a>
          <SocialLinks />
        </div>
        <div className="rights-reserved">
          <span>© 2023 Acropolis Tech. All rights reserved</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
