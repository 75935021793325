import React from "react";
import { SwiperSlide } from "swiper/react";
import ServiceSlider from "./ServiceSlider";
import data from "../../helper/data";
import Card from "../layouts/Card";

const ServiceSection = () => {
  const swiperRef = React.useRef();
  // const [hover, onHover] = React.useState(false);

  let timeout;

  const handleOnClick = () => {
    // console.log(swiperRef);
    if (swiperRef.current) swiperRef.current.autoplay.stop();
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (swiperRef.current) swiperRef.current.autoplay.start();
    }, 15000);
  };
  // const handleMouseEnter = () => {
  //   onHover(true);
  //   // console.log(swiperRef);
  //   if (swiperRef.current) swiperRef.current.autoplay.stop();
  // };
  // const handleMouseLeave = () => {
  //   // console.log(swiperRef);
  //   if (swiperRef.current) swiperRef.current.autoplay.start();
  // };
  return (
    <div className="services-main">
      <div className="services-main-heading">
        <h1 className="heading-primary">Our Services</h1>
        <p className="description-primary">
          With our web experimentation and feature experimentation tools, you
          can run experiments on your website or application, and gain valuable
          insights into how your users interact with different features and
          designs. Our platform supports a wide range of experiment types,
          including A/B tests, multivariate tests, and more, and our intuitive
          interface makes it easy to set up and manage your experiments.
        </p>
      </div>
      <div
        className="services-slider"
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        onClick={handleOnClick}
      >
        <ServiceSlider swiperRef={swiperRef}>
          {data.services.map((service) => {
            const { name, desc, logo } = service;
            return (
              <SwiperSlide key={name}>
                <Card name={name} desc={desc} logo={logo} />
              </SwiperSlide>
            );
          })}
        </ServiceSlider>
        <div className="swiper-pagination">
          <div className="swiper-pagination-wrapper"></div>
        </div>
      </div>
      <div className="services-lists">
        {data.services.map((service) => {
          const { name, desc, logo } = service;
          return <Card name={name} desc={desc} logo={logo} key={name} />;
        })}
      </div>
    </div>
  );
};

export default ServiceSection;
