import React from "react";
import { Link } from "react-router-dom";
import SocialLinks from "../ScocialLinks/SocialLinks";

const Footer = () => {
  return (
    <footer>
      <div className="footer-main">
        <div className="footer-items">
          <a className="footer-item footer-services">Services</a>
          {/* <a className="footer-item footer-aboutus">About Us</a> */}
          <Link to={"/blogs"} className="footer-item footer-blogs">
            Blogs
          </Link>
          <a className="footer-item btn-secondary">Contact Us</a>
          <SocialLinks />
        </div>

        <div className="rights-reserved">
          <span>© 2023 Acropolis Tech. All rights reserved</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
