const eventHandler = () => {
  document.body.addEventListener("click", ({ target }) => {
    // if (target.closest(".sm-screen .nav-item")) {
    //   const hamburger = target
    //     .closest(".nav-main")
    //     .querySelector(".hamburger-menu");
    //   hamburger?.classList.toggle("clicked");

    //   const navSmallScreen = target.closest(".nav-items.sm-screen");
    //   navSmallScreen?.classList.toggle("active");
    // }

    if (target.closest(".hamburger-menu")) {
      const hamburger = target.closest(".hamburger-menu");
      hamburger.classList.toggle("clicked");
      const navSmallScreen = document.querySelector(".nav-items.sm-screen");
      if (!navSmallScreen) return;
      navSmallScreen.classList.toggle("active");
    } else if (
      target.closest(".nav-item.services") ||
      target.closest(".footer-item.footer-services") ||
      target.closest(".services-button button")
    ) {
      const heroSection = document.querySelector(".services-main");
      if (!heroSection) return;
      const top = heroSection.offsetTop - 60;
      window?.scroll({ top, behavior: "smooth" });
    } else if (target.closest(".nav-item.tools")) {
      const heroSection = document.querySelector(".tools-main");
      if (!heroSection) return;
      const top = heroSection.offsetTop - 60;
      window?.scroll({ top, behavior: "smooth" });
    } else if (target.closest(".nav-item.clients")) {
      const clientsSection = document.querySelector(".clients-main");
      if (!clientsSection) return;
      const top = clientsSection.offsetTop - 120;
      //   const top = clientsSection.offsetTop;
      window?.scroll({ top, behavior: "smooth" });
    } else if (target.closest(".nav-item.about-us")) {
      const aboutUsSection = document.querySelector(".aboutUs-main");
      if (!aboutUsSection) return;
      const top = aboutUsSection.offsetTop - 60;
      //   const top = aboutUsSection.offsetTop;
      window?.scroll({ top, behavior: "smooth" });
    } else if (target.closest(".story-read-more")) {
      const readMore = target.closest(".story-read-more");
      if (!readMore) return;
      readMore.classList.toggle("active");
      const readLess = document.querySelector(".story-read-less");
      const storySummaryBox = document.querySelector(".story-body-summary-box");
      if (!readLess && !storySummaryBox) return;
      readLess.classList.toggle("active");
      storySummaryBox.classList.toggle("active");
    } else if (target.closest(".story-read-less")) {
      const readLess = target.closest(".story-read-less");
      if (!readLess) return;
      readLess.classList.toggle("active");
      const readMore = document.querySelector(".story-read-more");
      const storySummaryBox = document.querySelector(".story-body-summary-box");
      if (!readMore && !storySummaryBox) return;
      readMore.classList.toggle("active");
      storySummaryBox.classList.toggle("active");
    } else if (
      target.closest(".nav-item.btn-primary") ||
      target.closest(".footer-item.btn-secondary")
    ) {
      const contactUsSection = document.querySelector(
        ".contactus-section-main"
      );
      if (!contactUsSection) return;
      contactUsSection.classList.toggle("active");
    } else if (target.closest(".contactus-close-button")) {
      const contactUsSection = document.querySelector(
        ".contactus-section-main"
      );
      if (!contactUsSection) return;
      contactUsSection.classList.contains("active") &&
        contactUsSection.classList.remove("active");
    } else if (
      target.closest(".nav-item.blogs") ||
      target.closest(".footer-item.footer-blogs")
    ) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (
      target.closest(".nav-item.home") ||
      target.closest(".footer-item.footer-home")
    ) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  });
};

export default eventHandler;
