import React from "react";
import Story from "./Story";
import Mission from "./Mission";
import Services from "./Services";

const AboutUsSection = () => {
  return (
    <div className="aboutUs-main">
      <Story />
      <Mission />
      <Services />
    </div>
  );
};

export default AboutUsSection;
