import React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "../css/main.scss";

import App from "./App.jsx";
import Blogs from "./Blogs";
import BlogsDesc from "./blogsComponent/BlogsDescriptionPage/BlogsDesc";
import BlogsHomePage from "./blogsComponent/BlogsHomePage/BlogsHomePage";
import ErrorPage from "./components/ErrorPage/ErrorPage";

import eventHandler from "./utils/eventHandler.js";
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/blogs",
    element: <Blogs />,
    children: [
      {
        path: "",
        element: <BlogsHomePage />,
      },
      {
        path: ":blogName",
        element: <BlogsDesc />,
      },
    ],
  },
]);

const init = () => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
  eventHandler();
};

init();
