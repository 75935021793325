import React from "react";
import { Link } from "react-router-dom";
import data from "../../helper/blogsData";
import Card from "../Layouts/Card";

const BlogsElement = () => {
  const cardElems = data.map((blog, idx) => {
    const { title, img, headingSummary } = blog;
    const link = title.toLowerCase().replace(/\s/g, "_").replace(/\//g, "");
    return (
      <div className="blogs-item" key={title}>
        <Link
          to={`/blogs/${link}`}
          state={{ qparam: idx }}
          className="blogs-link"
        >
          <Card img={img} title={title} headingSummary={headingSummary} />
        </Link>
      </div>
    );
  });
  return <div className="blogs-home-main-box-context">{cardElems}</div>;
};

export default BlogsElement;
