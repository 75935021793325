import React, { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import emailjs from "@emailjs/browser";
import loadingImage from "../../../images/loading/loading.gif";

const validateEmail = (email) => {
  const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g;
  return re.test(email);
};

const validatePhone = (phone) => {
  const re = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g;
  return re.test(phone);
};

const ContactForm = ({ setIsSubmitted, setError }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const errors = {};

    // console.log({ name, email, phone, message });

    if (name.length < 3) {
      errors.name = "Name must be 3 characters long";
    }

    if (!validateEmail(email)) {
      errors.email = "Email is invalid";
    }

    if (phone && !validatePhone(phone)) {
      errors.phone = "Phone is invalid";
    }

    if (message.length < 25) {
      errors.message = "Message must be at least 25 characters long";
    }

    if (Object.keys(errors).length === 0) {
      // Submit form data
      //   console.log("Form data:", { name, email, phone, message });
      setErrors({});

      const templateParams = { name, email, phone, message };

      const defaultSubmitFn = () => {
        // console.log(templateParams);
        setIsSubmitted(true);
        setTimeout(() => {
          const contactUsSection = document.querySelector(
            ".contactus-section-main"
          );
          if (!contactUsSection) return;
          contactUsSection.classList.contains("active") &&
            contactUsSection.classList.remove("active");
        }, 2000);
      };

      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          function (response) {
            // console.log("SUCCESS!", response.status, response.text);
            // Reset form fields
            setName("");
            setEmail("");
            setPhone("");
            setMessage("");
            setIsLoading(false);
            setError(false);
            defaultSubmitFn();
            setTimeout(() => {
              setIsSubmitted(false);
            }, 2500);
          },
          function (error) {
            // console.log("FAILED...", error);
            setError(true);
            defaultSubmitFn();
            setTimeout(() => {
              setError(false);
              setIsSubmitted(false);
            }, 2500);
          }
        );
    } else {
      setErrors(errors);
      setError(true);
      setIsLoading(false);
    }
  };

  return (
    <form className="contactus-form" onSubmit={submitHandler}>
      <div className="contactus-form-name">
        <div className="contactus-form-field">
          <label htmlFor="form-name">Name</label>
          <input
            type="text"
            id="form-name"
            className={errors.name ? "form-error" : ""}
            placeholder="Name goes here"
            value={name}
            onChange={({ target }) => setName(target.value)}
          />
          <sup id="required-text-1">*</sup>
        </div>
        <p className="form-error-text">{errors.name ? errors.name : ""}</p>
      </div>

      <div className="contactus-form-email">
        <div className="contactus-form-field">
          <label htmlFor="form-email">Email</label>
          <input
            type="text"
            id="form-email"
            className={errors.email ? "form-error" : ""}
            placeholder="Email goes here"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
          <sup id="required-text-2">*</sup>
        </div>
        <p className="form-error-text">{errors.email ? errors.email : ""}</p>
      </div>

      <div className="contactus-form-phone">
        <div className="contactus-form-field">
          <label htmlFor="form-phone">Phone</label>
          <input
            type="tel"
            id="form-phone"
            className={errors.phone ? "form-error" : ""}
            placeholder="+1-222-333-4444"
            value={phone}
            onChange={({ target }) => setPhone(target.value)}
          />
        </div>
        <p className="form-error-text">{errors.phone ? errors.phone : ""}</p>
      </div>

      <div className="contactus-form-message">
        <div className="contactus-form-field">
          <label htmlFor="form-message">Message</label>
          <textarea
            type="text"
            id="form-message"
            className={errors.message ? "form-error" : ""}
            placeholder="Message goes here"
            maxLength={2000}
            value={message}
            onChange={({ target }) => setMessage(target.value)}
          />
          <sup id="required-text-3">*</sup>
        </div>
        <div id="the-count">
          <span id="current">{message.length}</span>
          <span id="maximum">/ 2000</span>
        </div>
        <p className="form-error-text">
          {errors.message ? errors.message : ""}
        </p>
      </div>

      <div className="contactus-form-button">
        {!isLoading ? (
          <button type="submit">
            Send{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g transform="rotate(-45 12 12)">
                <path
                  fill="#233340"
                  d="M2.01 21L23 12L2.01 3L2 10l15 2l-15 2l.01 7z"
                />
              </g>
            </svg>
          </button>
        ) : (
          <div className="loading-gif">
            <img src={loadingImage} alt="Loading ..." />
          </div>
        )}
      </div>
      <ReactTooltip
        className="tooltip description-primary"
        anchorId="required-text-1"
        content={"This field is required!"}
      />
      <ReactTooltip
        className="tooltip description-primary"
        anchorId="required-text-2"
        content={"This field is required!"}
      />
      <ReactTooltip
        className="tooltip description-primary"
        anchorId="required-text-3"
        content={"This field is required!"}
      />
    </form>
  );
};

export default ContactForm;
