import React from "react";
import { Link } from "react-router-dom";

const Button = (props) => {
  const { btn_type, to } = props;
  return (
    <Link className={btn_type} to={to}>
      {props.children}
    </Link>
  );
};

export default Button;
