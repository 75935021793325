import React from "react";

const Mission = () => {
  return (
    <div className="mission-section aboutUs-item">
      <h1 className="heading-primary">Our Mission</h1>
      <div className="description">
        <div className="mission-summary">
          <span>
            Acropolis Tech is a trusted solutions partner with a mission to help
            clients reduce their development, design, and QA costs without
            compromising on quality. Our team of experts is passionate about
            delivering innovative solutions that meet your unique business
            needs, and we're committed to building long-lasting relationships
            based on trust and respect.
          </span>
          <span>
            We believe in creating a sustainable and eco-friendly workspace that
            fosters growth and development for our team. We're dedicated to
            investing in our team and providing all the necessary support to
            help them grow within the organization. By nurturing our team, we
            can provide the best solutions for our clients.
          </span>
          <span>
            At Acropolis Tech, we're committed to delivering exceptional service
            to our clients. Our values of honesty, transparency, and integrity
            guide everything we do, and we're dedicated to providing highly
            experienced resources at a great cost. Whether you need help with
            software development, web design, or digital marketing, we have the
            expertise to help you achieve your goals. Join our family of
            satisfied clients and experience the Acropolis Tech difference.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Mission;
