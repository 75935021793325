import React from "react";
import Navbar from "./components/Navbar/Navbar";
import BodySection from "./components/BodySection/BodySection";
import Footer from "./components/Footer/Footer";
import ContactUs from "./components/ContactUs/ContactUs";
import GoToTopButton from "./components/GoTop/GoToTopButton";

const App = () => {
  return (
    <>
      <ContactUs />
      <GoToTopButton />
      <Navbar />
      <BodySection />
      <Footer />
    </>
  );
};

export default App;
