import React from "react";
import { Link } from "react-router-dom";

import logo from "../../../images/main_logo_lg.png";

const Navbar = () => {
  return (
    <header>
      <nav className="nav-main">
        <div className="logo-main">
          <a href="/">
            <img src={logo} alt="Acropolis Tech" className="logo-main-img" />
          </a>
        </div>
        <div className="nav-items lg-screen">
          <NavItem />
        </div>
        <a className="nav-item btn-primary">CONTACT US</a>
        <div className="hamburger-menu">
          <div className="line-1"></div>
          <div className="line-2"></div>
          <div className="line-3"></div>
        </div>
        <div className="nav-items sm-screen">
          <NavItem />
        </div>
      </nav>
    </header>
  );
};

const NavItem = () => (
  <>
    {" "}
    <Link to={"/"} className="nav-item home">
      HOME
    </Link>
    <a className="nav-item about-us">ABOUT US</a>
    <Link to={"/blogs"} className="nav-item blogs">
      BLOGS
    </Link>
  </>
);

export default Navbar;
