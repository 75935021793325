import React from "react";

const Card = ({ img, title, headingSummary }) => {
  return (
    <div className="blogs-card">
      <div className="blogs-card-image">
        <img src={img} alt={title} />
      </div>
      <div className="blogs-card-title">
        <h3>{title}</h3>
        <div className="summary">{headingSummary}</div>
      </div>
    </div>
  );
};

export default Card;
