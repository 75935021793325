// import Swiper core and required modules
import React from "react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import data from "../../helper/data";

export default (props) => {
  //   console.log(props);
  const onInit = (Swiper) => {
    props.swiperRef.current = Swiper;
    // console.log(props.swiperRef.current);
  };
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      spaceBetween={50}
      centeredSlides={true}
      slidesPerView={1}
      //   navigation
      pagination={{
        el: ".swiper-pagination-wrapper",
        clickable: true,
        type: "bullets",
        renderBullet: (index, className) => {
          return (
            '<span class="' +
            className +
            '">' +
            "<div>" +
            data.services[index].shortName +
            "</div>" +
            "<i></i>" +
            "<b></b>" +
            "</span>"
          );
        },
      }}
      allowTouchMove={false}
      scrollbar={{ draggable: true }}
      speed={3000}
      loop={true}
      autoplay={{
        enabled: true,
        delay: 1,
        // disableOnInteraction: false,
        // pauseOnMouseEnter: true,
      }}
      //   onSwiper={function (swiper) {
      //     swiper.autoplay.pauseOnMouseEnter = true;
      //     swiper.autoplay.disableOnInteraction = ;
      //     console.log(swiper);
      //   }}
      //   onSlideChange={() => console.log("slide change")}
      onInit={onInit}
    >
      {props.children}
    </Swiper>
  );
};
